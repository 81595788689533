import React, { useState, useEffect } from "react";
import "./Settings.css";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { observer } from "mobx-react-lite";

const Settings: React.FC = () => {
  const [comma, setComma] = useState<number>(4);

  useEffect(() => {
    const storedValue = localStorage.getItem("comma");
    if (storedValue) {
      setComma(parseInt(storedValue, 10));
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const parsedValue = parseInt(inputValue, 10);
    
    if (parsedValue >= 0 || inputValue === '') {
      if(inputValue === ''){
        setComma(0)
      }
      else{
        setComma(parsedValue);
      }
    }
  };

  const handleSave = () => {
    localStorage.setItem("comma", comma.toString());
  };

  return (
    <Col>
      <Row className="row-bg silverBG">
        <Col>
          <Row>
            <label className="text-center formLabel">Настройки</label>
          </Row>
          <Row className="row-test">
            <Stack gap={1}>
              <Form.Label className="text-center">
                Введите количество знаков после запятой
              </Form.Label>
              <Form.Control
                placeholder="Количество знаков после запятой"
                className="m-2 text-center input-label"
                type="number"
                min="0"
                value={comma}
                onChange={handleChange}
              />
              <Button className="greenButton m-2" onClick={handleSave}>
                Сохранить
              </Button>
            </Stack>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default observer(Settings);
