import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig,
  } from "axios";

import { appStore } from "../../Store/appStore";
import { IHttpService } from "./types";
  
  
  class HttpService implements IHttpService {
    http: AxiosInstance;
  
    constructor() {
      this.http = axios.create({
        //baseURL: "http://91.222.238.12:8000/api/",
        baseURL: "https://fugitive-clean.ru/api/",
      });
  
      this.http.interceptors.request.use((config: InternalAxiosRequestConfig) => {
        config.headers &&
          (config.headers.Authorization = `Bearer ${localStorage.getItem(
            "token"
          )}`);
        return config;
      });
  
      this.http.interceptors.response.use(
        (config: AxiosResponse) => {
          return config;
        },
        async (error) => {
          if (error.response.status === 401) {
            appStore.userStore.logout();
          }
          throw error;
        }
      );
    }
  
    async get<T>(url: string): Promise<T> {
      try {
        const { data } = await this.http.get<T>(url);
        if (data) return data;
        throw new Error("data not found");
      } catch (error) {
        throw new Error(`${error}`);
      }
    }

    async patch<T, D = any>(url: string, patchData?: D): Promise<T> {
      try {
        const { data } = await this.http.patch<T, AxiosResponse<T>, D>(
          url,
          patchData
        );
        if (data) return data;
        throw new Error("Error while patching");
      } catch (error) {
        throw new Error(`${error}`);
      }
    }
    
    async post<T, D = any>(url: string, newData?: D): Promise<T> {
      try {
        
        const { data, status } = await this.http.post<T, AxiosResponse<T>, D>(
          url,
          newData
        );
        if (data || status < 300) return data;
        throw new Error("error of creating");
      } catch (error) {
        throw new Error(`${error}`);
      }
    }
    
    async postWithFile<T, D = any>(url: string, newData?: D): Promise<T> {
      try {
        console.log("postWithFile")
        console.log(newData)
        const { data, status } = await this.http.post<T, AxiosResponse<T>, D>(
          url,
          newData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        if (data || status < 300) return data;
        throw new Error("error of creating");
      } catch (error) {
        throw new Error(`${error}`);
      }
    }
  
    async put<T, D = any>(url: string, updData?: D): Promise<T> {
      try {
        const { data } = await this.http.put<T, AxiosResponse<T>, D>(
          url,
          updData
        );
        if (data) return data;
        throw new Error("error of updating");
      } catch (error) {
        throw new Error(`${error}`);
      }
    }
  
    async delete<T>(url: string): Promise<T> {
      try {
        const { data } = await this.http.delete<T, AxiosResponse<T>>(url);
        if (data) return data;
        throw new Error("error of deleting");
      } catch (error) {
        throw new Error(`${error}`);
      }
    }
  }
  
  export default new HttpService();
  