import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import {
  IUndergroundContentDictionary,
  IUndergroundDictionary,
} from "../../../../StoreCollections/dictionaryStore";
import Loading from "../../../Loading/Loading";
import { regex } from "./RegExpr";
import { InlineMath } from "react-katex";

interface UndergroundProps {
  onSelectedDictionaryChange: (value: IUndergroundDictionary | null) => void;
  onSelectedDictionaryContentChange: (
    value: IUndergroundContentDictionary | null
  ) => void;
  onVolumeChange: (value: number) => void;
  mode: string;
  selectedCalculation: any;
}

const OpenMiningCH4Calculation: React.FC<UndergroundProps> = ({
  onSelectedDictionaryChange,
  onSelectedDictionaryContentChange,
  onVolumeChange,
  mode,
  selectedCalculation,
}) => {
  ///Variables
  const { dictionaryStore, commonStore } = appStore;

  const fixedValue = parseInt(localStorage.getItem("comma")!);

  const [isCustomEf, setIsCustomEf] = useState(true);

  const [customEfCO2, setCustomEfCO2] = useState("");

  const [customEfCH4, setCustomEfCH4] = useState("");

  const [selectedDictionary, setSelectedDictionary] =
    useState<IUndergroundDictionary | null>(null);

  const [selectedDictionaryContent, setSelectedDictionaryContent] =
    useState<IUndergroundContentDictionary | null>(null);

  const [volume, setVolume] = useState("");

  ///useEffects
  useEffect(() => {
    dictionaryStore.fetchUnderground();
    dictionaryStore.fetchUndergroundContent();
    return () => {
      dictionaryStore.clean();
    };
  }, []);

  useEffect(() => {
    if (dictionaryStore.underground && dictionaryStore.underground.length > 0) {
      setSelectedDictionary(dictionaryStore.underground[0]);
    }
  }, [dictionaryStore.underground]);

  useEffect(() => {
    if (mode === "edit") {
      if (selectedCalculation) {
        setVolume(Number(selectedCalculation.volume).toFixed(fixedValue));
      }
    }
  }, [selectedCalculation]);

  useEffect(() => {
    if (
      dictionaryStore.underground.length > 0 &&
      mode === "edit" &&
      selectedCalculation != undefined
    ) {
      const foundCalc = dictionaryStore.underground.find(
        (calc: IUndergroundDictionary) =>
          Number(calc.p_co2) === Number(selectedCalculation.p_co2) &&
          Number(calc.p_ch4) === Number(selectedCalculation.p_ch4)
      );
      if (foundCalc) {
        setSelectedDictionary(foundCalc);
      } else {
        console.log("No matching calculation found");
      }
    }
  }, [dictionaryStore.underground]);

  useEffect(() => {
    if (
      dictionaryStore.undergroundContent.length > 0 &&
      mode === "edit" &&
      selectedCalculation != undefined
    ) {
      const foundCalc = dictionaryStore.undergroundContent.find(
        (calc: IUndergroundContentDictionary) =>
          Number(calc.ef_co2) === Number(selectedCalculation.ef_co2) &&
          Number(calc.ef_ch4) === Number(selectedCalculation.ef_ch4)
      );
      if (foundCalc) {
        setSelectedDictionaryContent(foundCalc);
        setIsCustomEf(false);
      } else {
        setIsCustomEf(true);
        setCustomEfCH4(Number(selectedCalculation.ef_ch4).toFixed(fixedValue));
        setCustomEfCO2(Number(selectedCalculation.ef_co2).toFixed(fixedValue));
      }
    }
  }, [dictionaryStore.undergroundContent]);

  useEffect(() => {
    if (!isCustomEf) {
      onSelectedDictionaryContentChange(selectedDictionaryContent);
    } else {
      const custom: IUndergroundContentDictionary = {
        id: 0,
        name: "Фактическое значение",
        ef_co2: customEfCO2,
        ef_ch4: customEfCH4,
      };
      onSelectedDictionaryContentChange(custom);
    }
  }, [selectedDictionaryContent, isCustomEf, customEfCH4, customEfCO2]);

  useEffect(() => {
    onVolumeChange(Number(volume));
  }, [volume]);

  useEffect(() => {
    onSelectedDictionaryChange(selectedDictionary);
  }, [selectedDictionary]);

  ///Methods
  const handleSelectDictionary = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedItem = dictionaryStore.underground.find(
      (item) => item.id === Number(event.target.value)
    );
    setSelectedDictionary(selectedItem ?? null);
  };

  const handleSelectDictionaryContent = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value;
    if (selectedValue === "custom") {
      setIsCustomEf(true);
    } else {
      const selectedItem = dictionaryStore.undergroundContent.find(
        (item) => item.id === Number(event.target.value)
      );
      setSelectedDictionaryContent(selectedItem ?? null);
      setIsCustomEf(false);
    }
  };

  if (commonStore.isLoading) {
    return <Loading text="полей" />;
  }

  return (
    <>
      <Form.Label>
        Расход углеводородной смеси за выбранный период, тыс.
        <InlineMath math="м^{3}" />
      </Form.Label>
      <Form.Control
        value={volume}
        onChange={(e) => {
          if (regex(mode).test(e.target.value)) {
            setVolume(e.target.value);
          }
        }}
        type="text"
      />
      <Form.Label>Условия измерения плотности CO2 и CH4</Form.Label>
      <Form.Select
        value={selectedDictionary?.id || ""}
        onChange={handleSelectDictionary}
      >
        {dictionaryStore.underground.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
      <Form.Label>Способ расчета</Form.Label>
      <Form.Select
        value={selectedDictionaryContent?.id || ""}
        onChange={handleSelectDictionaryContent}
      >
        <option value="custom">Фактическое значение</option>
        {dictionaryStore.undergroundContent.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
      {isCustomEf && (
        <>
          <Form.Label>Содержание CO2, %</Form.Label>
          <Form.Control
            value={customEfCO2}
            onChange={(e) => {
              if (regex(mode).test(e.target.value)) {
                setCustomEfCO2(e.target.value);
              }
            }}
            type="text"
          />
          <Form.Label>Содержание CH4, %</Form.Label>
          <Form.Control
            value={customEfCH4}
            onChange={(e) => {
              if (regex(mode).test(e.target.value)) {
                setCustomEfCH4(e.target.value);
              }
            }}
            type="text"
          />
        </>
      )}
    </>
  );
};

export default observer(OpenMiningCH4Calculation);
