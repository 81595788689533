import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { appStore } from "../../../Store/appStore";
import { IUndergroundDictionary } from "../../../StoreCollections/dictionaryStore";
import Loading from "../../Loading/Loading";
import { regex } from "../../Calculations/components/components/RegExpr";
import { InlineMath } from "react-katex";

const FuggitiveTransfer = () => {
  const { dictionaryStore, commonStore } = appStore;
  const fixedValue = parseInt(localStorage.getItem("comma")!);
  const [fugitiveOption, setFugitiveOption] = useState<undefined | string>(
    "CH4"
  );
  const [volume, setVolume] = useState("");
  const [fugResult, setFugResult] = useState("");
  const [selectedDictionary, setSelectedDictionary] =
    useState<IUndergroundDictionary | null>(null);

  useEffect(() => {
    dictionaryStore.fetchUnderground();
  }, []);

  useEffect(() => {
    if (dictionaryStore.underground && dictionaryStore.underground.length > 0) {
      setSelectedDictionary(dictionaryStore.underground[0]);
    }
  }, [dictionaryStore.underground]);

  const handleSelectDictionary = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedItem = dictionaryStore.underground.find(
      (item) => item.id === Number(event.target.value)
    );
    setSelectedDictionary(selectedItem ?? null);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFugitiveOption(event.target.value);
  };

  useEffect(() => {
    console.log("hello");
    if (fugitiveOption && selectedDictionary && volume) {
      try {
        let result: number;

        if (fugitiveOption === "CH4") {
          result = (Number(volume) * selectedDictionary.p_ch4) / 1000;
        } else if (fugitiveOption === "CO2") {
          result = (Number(volume) * selectedDictionary.p_co2) / 1000;
        } else {
          throw new Error("Invalid fugitive option");
        }

        setFugResult(result.toFixed(fixedValue));
      } catch (error) {
        console.error("Error calculating fugitive result:", error);
        setFugResult("");
      }
    }
  }, [fugitiveOption, volume, selectedDictionary]);

  return (
    <>
      {commonStore.isLoading ? (
        <Loading text="справочников для виджета" />
      ) : (
        <>
          <Col className="text-center mt-3">
            <Stack className="stack-bg m-2">
              <label className="mb-3 mt-5">
                <h3>
                  <Form.Label>
                    Перевод СН4 и СО2 из <InlineMath math="м^{3}" /> в т{" "}
                  </Form.Label>
                  <Form.Select
                    value={fugitiveOption}
                    className="text-center"
                    onChange={handleSelectChange}
                  >
                    <option value="CH4">CH4</option>
                    <option value="CO2">CO2</option>
                  </Form.Select>
                  <Form.Label>Условия измерения плотности CO2 и CH4</Form.Label>
                  <Form.Select
                    className="text-center"
                    value={selectedDictionary?.id || ""}
                    onChange={handleSelectDictionary}
                  >
                    {dictionaryStore.underground.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Label>Количество газа для перевода</Form.Label>
                  <Form.Control
                    className="text-center"
                    value={volume}
                    onChange={(e) => {
                      if (regex().test(e.target.value)) {
                        setVolume(e.target.value);
                      }
                    }}
                    type="text"
                  />
                  <Form.Label>Результат</Form.Label>
                  <Form.Control
                    value={fugResult}
                    type="text"
                    className="text-center"
                    disabled
                  />
                </h3>
              </label>
            </Stack>
          </Col>
        </>
      )}
    </>
  );
};
export default observer(FuggitiveTransfer);
