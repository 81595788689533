import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import { IHandlingUndergroundCH4Dictionary } from "../../../../StoreCollections/dictionaryStore";
import Loading from "../../../Loading/Loading";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import { regex } from "./RegExpr";
import { InlineMath } from "react-katex";

interface HandlingUndergroundProps {
  onSelectedDictionaryChange: (
    value: IHandlingUndergroundCH4Dictionary | null
  ) => void;
  onVolumeChange: (value: number) => void;
  mode: string;
  selectedCalculation: any;
}

const HandlingUndergroundCalculation: React.FC<HandlingUndergroundProps> = ({
  onSelectedDictionaryChange,
  onVolumeChange,
  mode,
  selectedCalculation,
}) => {
  ///Variables
  const { dictionaryStore, commonStore } = appStore;

  const [selectedDictionary, setSelectedDictionary] =
    useState<IHandlingUndergroundCH4Dictionary | null>(null);

  const [volume, setVolume] = useState("");
  const fixedValue = parseInt(localStorage.getItem("comma")!);

  ///useEffects
  useEffect(() => {
    dictionaryStore.fetchHandlingUndergroundCH4();
    return () => {
      dictionaryStore.clean();
    };
  }, []);

  useEffect(() => {
    if (
      dictionaryStore.handlingUndergroundCH4 &&
      dictionaryStore.handlingUndergroundCH4.length > 0
    ) {
      setSelectedDictionary(dictionaryStore.handlingUndergroundCH4[0]);
    }
  }, [dictionaryStore.handlingUndergroundCH4]);

  useEffect(() => {
    if (mode === "edit") {
      if (selectedCalculation) {
        console.log(fixedValue);
        setVolume(Number(selectedCalculation.volume).toFixed(fixedValue));
      }
    }
  }, [selectedCalculation]);

  useEffect(() => {
    if (
      dictionaryStore.handlingUndergroundCH4.length > 0 &&
      mode === "edit" &&
      selectedCalculation != undefined
    ) {
      const foundCalc = dictionaryStore.handlingUndergroundCH4.find(
        (calc: IHandlingUndergroundCH4Dictionary) =>
          Number(calc.ef) === Number(selectedCalculation.ef) &&
          Number(calc.koef_transform) ===
            Number(selectedCalculation.koef_transform)
      );
      if (foundCalc) {
        setSelectedDictionary(foundCalc);
      } else {
        console.log("No matching calculation found");
      }
    }
  }, [dictionaryStore.handlingUndergroundCH4]);

  useEffect(() => {
    onSelectedDictionaryChange(selectedDictionary);
  }, [selectedDictionary]);

  useEffect(() => {
    onVolumeChange(Number(volume));
  }, [volume]);

  ///Methods
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = dictionaryStore.handlingUndergroundCH4.find(
      (item) => item.id === Number(event.target.value)
    );
    setSelectedDictionary(selectedItem ?? null);
  };

  if (commonStore.isLoading) {
    return <Loading text="Полей" />;
  }

  return (
    <>
      <Form.Label>Объем угля добытого подземным способом, т</Form.Label>
      <Form.Control
        value={volume}
        onChange={(e) => {
          if (regex(mode).test(e.target.value)) {
            setVolume(e.target.value);
          }
        }}
        type="text"
      />
      <Form.Label>
        Выберите коэффициент выбросов CH4, <InlineMath math="м^{3}" />
        /т
      </Form.Label>
      <Form.Select
        value={selectedDictionary?.id || ""}
        onChange={handleSelectChange}
      >
        {dictionaryStore.handlingUndergroundCH4.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default observer(HandlingUndergroundCalculation);
