import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import { IOpenMiningCO2Dictionary } from "../../../../StoreCollections/dictionaryStore";
import Loading from "../../../Loading/Loading";
import { regex } from "./RegExpr";

interface OpenMiningCO2Props {
  onSelectedDictionaryChange: (value: IOpenMiningCO2Dictionary | null) => void;
  onVolumeChange: (value: number) => void;
  mode: string;
  selectedCalculation: any;
}

const OpenMiningCO2Calculation: React.FC<OpenMiningCO2Props> = ({
  onSelectedDictionaryChange,
  onVolumeChange,
  mode,
  selectedCalculation,
}) => {
  const { dictionaryStore, commonStore } = appStore;

  const fixedValue = parseInt(localStorage.getItem("comma")!);

  const [selectedDictionary, setSelectedDictionary] =
    useState<IOpenMiningCO2Dictionary | null>(null);
  const [volume, setVolume] = useState("");

  useEffect(() => {
    dictionaryStore.fetchOpenMiningCO2();
    return () => {
      dictionaryStore.clean();
    };
  }, []);

  useEffect(() => {
    if (
      dictionaryStore.openMiningCO2 &&
      dictionaryStore.openMiningCO2.length > 0
    ) {
      setSelectedDictionary(dictionaryStore.openMiningCO2[0]);
    }
  }, [dictionaryStore.openMiningCO2]);

  useEffect(() => {
    onSelectedDictionaryChange(selectedDictionary);
  }, [selectedDictionary]);

  useEffect(() => {
    onVolumeChange(Number(volume));
  }, [volume]);

  useEffect(() => {
    if (mode === "edit") {
      if (selectedCalculation) {
        setVolume(Number(selectedCalculation.volume).toFixed(fixedValue));
      }
    }
  }, [selectedCalculation]);

  useEffect(() => {
    if (
      dictionaryStore.openMiningCO2.length > 0 &&
      mode === "edit" &&
      selectedCalculation != undefined
    ) {
      const foundCalc = dictionaryStore.openMiningCO2.find(
        (calc: IOpenMiningCO2Dictionary) =>
          Number(calc.ef) === Number(selectedCalculation.ef)
      );
      if (foundCalc) {
        setSelectedDictionary(foundCalc);
      } else {
        console.log("No matching calculation found");
      }
    }
  }, [dictionaryStore.openMiningCO2]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = dictionaryStore.openMiningCO2.find(
      (item) => item.id === Number(event.target.value)
    );
    setSelectedDictionary(selectedItem ?? null);
  };

  if (commonStore.isLoading) {
    return <Loading text="полей" />;
  }

  return (
    <>
      <Form.Label>Расход топлива за выбранный период, т</Form.Label>
      <Form.Control
        value={volume}
        onChange={(e) => {
          if (regex(mode).test(e.target.value)) {
            setVolume(e.target.value);
          }
        }}
        type="text"
      />
      <Form.Label>Тип топлива</Form.Label>
      <Form.Select
        value={selectedDictionary?.id || ""}
        onChange={handleSelectChange}
      >
        {dictionaryStore.openMiningCO2.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default observer(OpenMiningCO2Calculation);
