import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { appStore } from "../../../Store/appStore";
import { IDensityCoeffDictionary } from "../../../StoreCollections/dictionaryStore";
import Loading from "../../Loading/Loading";
import { regex } from "../../Calculations/components/components/RegExpr";

const FuelConversion = () => {
  const { dictionaryStore, commonStore } = appStore;

  const fixedValue = parseInt(localStorage.getItem("comma")!);

  const [volume, setVolume] = useState("");
  const [fuelResult, setFuelResult] = useState("");
  const [selectedDictionary, setSelectedDictionary] =
    useState<IDensityCoeffDictionary | null>(null);

  useEffect(() => {
    dictionaryStore.fetchDensityCoeff();
  }, []);

  useEffect(() => {
    if (
      dictionaryStore.densityCoeff &&
      dictionaryStore.densityCoeff.length > 0
    ) {
      setSelectedDictionary(dictionaryStore.densityCoeff[0]);
    }
  }, [dictionaryStore.densityCoeff]);

  const handleSelectDictionary = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedItem = dictionaryStore.densityCoeff.find(
      (item) => item.id === Number(event.target.value)
    );
    setSelectedDictionary(selectedItem ?? null);
  };

  useEffect(() => {
    if (selectedDictionary && volume) {
      try {
        let result: number;

        result = (Number(volume) * selectedDictionary.p) / 1000;

        setFuelResult(result.toFixed(fixedValue));
      } catch (error) {
        console.error("Error calculating fuel result:", error);
        setFuelResult("");
      }
    }
  }, [volume, selectedDictionary]);

  return (
    <>
      {commonStore.isLoading ? (
        <Loading text="справочников для виджета" />
      ) : (
        <>
          <Col className="text-center mt-3">
            <Stack className="stack-bg m-2">
              <label className="mb-3 mt-5">
                <h3>
                  <Form.Label>Марка топлива</Form.Label>
                  <Form.Select
                    className="text-center"
                    value={selectedDictionary?.id || ""}
                    onChange={handleSelectDictionary}
                  >
                    {dictionaryStore.densityCoeff.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Label>Количество топлива для перевода</Form.Label>
                  <Form.Control
                    className="text-center"
                    value={volume}
                    onChange={(e) => {
                      if (regex().test(e.target.value)) {
                        setVolume(e.target.value);
                      }
                    }}
                    type="text"
                  />
                  <Form.Label>Результат</Form.Label>
                  <Form.Control
                    value={fuelResult}
                    type="text"
                    className="text-center"
                    disabled
                  />
                </h3>
              </label>
            </Stack>
          </Col>
        </>
      )}
    </>
  );
};
export default observer(FuelConversion);
