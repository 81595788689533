import { Col, Form, Stack } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { appStore } from "../../Store/appStore";
import { useEffect } from "react";
import Loading from "../Loading/Loading";

const Profile = () => {
  const { userStore, commonStore } = appStore;

  useEffect(() => {
    userStore.getInfo();
    userStore.getStat();
  }, []);
  return (
    <>
      {commonStore.isLoading ? (
        <Loading text="данных пользователя" />
      ) : (
        <>
          {" "}
          <Col className="text-center mt-3">
            <Stack className="stack-bg m-2">
              <label className="mb-3 mt-5">
                <h3>
                  <Form.Label>Компания</Form.Label>
                  <Form.Control
                    type="text"
                    className="text-center"
                    value={userStore.user?.company.name || "Отсутствует"}
                    disabled
                  />
                  <Form.Label>Почтовый адрес компании</Form.Label>
                  <Form.Control
                    type="text"
                    className="text-center"
                    value={userStore.user?.company.email || "Отсутствует"}
                    disabled
                  />
                  <Form.Label>ОГРН компании</Form.Label>
                  <Form.Control
                    type="text"
                    className="text-center"
                    value={userStore.user?.company.ogrn || "Отсутствует"}
                    disabled
                  />
                  <Form.Label>ОКВЭД компании</Form.Label>
                  <Form.Control
                    type="text"
                    className="text-center"
                    value={userStore.user?.company.okvd || "Отсутствует"}
                    disabled
                  />
                  <Form.Label>Регион компании</Form.Label>
                  <Form.Control
                    type="text"
                    className="text-center"
                    value={userStore.user?.company.region || "Отсутствует"}
                    disabled
                  />
                  <Form.Label>Имя пользователя</Form.Label>
                  <Form.Control
                    type="text"
                    className="text-center"
                    value={userStore.user?.first_name || "Отсутствует"}
                    disabled
                  />
                  <Form.Label>Фамилия пользователя</Form.Label>
                  <Form.Control
                    type="text"
                    className="text-center"
                    value={userStore.user?.last_name || "Отсутствует"}
                    disabled
                  />
                  <Form.Label>Email пользователя</Form.Label>
                  <Form.Control
                    type="text"
                    className="text-center"
                    value={userStore.user?.email || "Отсутствует"}
                    disabled
                  />
                </h3>
              </label>
            </Stack>
          </Col>
        </>
      )}
    </>
  );
};
export default observer(Profile);
