export const regex = (mode?: string): RegExp => {
    let reg: RegExp;
    const decimalPlaces = localStorage.getItem('comma');
    console.log("reges");
    console.log(decimalPlaces);
    if (decimalPlaces !== null && !isNaN(parseInt(decimalPlaces))) {
      const places = Math.max(0, Math.min(4, parseInt(decimalPlaces)));
      reg = new RegExp(`^\\d*\\.?\\d{0,${places}}$`);
    } else if (mode === "edit") {
      reg = /^(\d*\.?\d{0,2})?$/
    } else {
      reg = /^(\d*\.?\d{0,4})?$/
    }
    
    return reg;
  }
  